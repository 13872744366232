import React, { useState } from 'react'
import {
    DndContext,
    useSensor,
    MouseSensor,
    TouchSensor,
    KeyboardSensor,
    useSensors,
    UniqueIdentifier,
    DroppableContainer,
    ClientRect,
} from '@dnd-kit/core';
import {
    restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import { rectIntersection, closestCenter } from '@dnd-kit/core';

const DndWrapper = ({ children }: { children: JSX.Element | JSX.Element[] }) => {

    const activationConstraint = {
        // delay: 30,
        // tolerance: 0,
        distance: 2,
    } as const
    //#region sensors
    const mouseSensor = useSensor(MouseSensor, { activationConstraint });
    const touchSensor = useSensor(TouchSensor, { activationConstraint });
    // const keyboardSensor = useSensor(KeyboardSensor, {});
    const sensors = useSensors(mouseSensor, touchSensor);
    //#endregion sensors

    const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

    function handleDragStart() { //
        console.log("dragging")
    }

    // prioritizes workBlock containers
    function customCollisionDetectionAlgorithm({
        droppableContainers,
        ...args
    }: any) {
        const collisionRect: ClientRect = args.collisionRect
        const workOnContainers = (droppableContainers as DroppableContainer[]).filter(({ id }) => typeof id === 'string' && id.startsWith("i.event."));
        const workOnCollisions = rectIntersection({ ...args, droppableContainers: workOnContainers });
        if (workOnCollisions.length > 0) return workOnCollisions;

        const possibleIntersections = rectIntersection({ ...args, droppableContainers, collisionRect: expandCollisionRect(collisionRect) });
        const possibleContainerIds = new Set(possibleIntersections.map(({ id }) => id));

        const withinRectContainers = (droppableContainers as DroppableContainer[]).filter(({ id }) => possibleContainerIds.has(id));

        const centerCollisions = closestCenter({ ...args, droppableContainers: withinRectContainers });

        return centerCollisions
        // .filter(({ id }) => possibleContainerIds.has(id));

        // // First, let's see if the `trash` droppable rect is intersecting
        // const rectIntersectionCollisions = rectIntersection({
        //     ...args,
        //     droppableContainers: droppableContainers.filter(({ id }) => id === 'trash')
        // });

        // // Collision detection algorithms return an array of collisions
        // if (rectIntersectionCollisions.length > 0) {
        //     // The trash is intersecting, return early
        //     return rectIntersectionCollisions;
        // }
        // // export interface Collision {
        // //     id: UniqueIdentifier;
        // //     data?: Data;
        // // }
        // // const col: Collision = []

        // // Compute other collisions
        // return closestCorners({
        //     ...args,
        //     droppableContainers: droppableContainers.filter(({ id }) => id !== 'trash')
        // });
    };


    return (
        <DndContext
            sensors={sensors}
            onDragStart={handleDragStart}
            // onDragEnd={handleDragEnd}
            modifiers={[restrictToWindowEdges]} //2rem, calc((100% - 3.25rem) / 7)
            collisionDetection={customCollisionDetectionAlgorithm}
        >
            {children}
        </DndContext>
    )

}

export default DndWrapper


const expandCollisionRect = (collisionRect: ClientRect) => {
    const addOn = Math.min(collisionRect.width, collisionRect.height) / 2;
    // const additionalTargetHeight = collisionRect.height / 2;
    // const additionalTargetWidth = collisionRect.width / 2;
    const doubledCollisionRect = {
        ...collisionRect,
        width: collisionRect.width + addOn * 2,
        height: collisionRect.height + addOn * 2,
        top: collisionRect.top - addOn,
        left: collisionRect.left - addOn,
        right: collisionRect.right + addOn,
        bottom: collisionRect.bottom + addOn
    }
    return doubledCollisionRect
}

// /**
//  * Returns the intersecting rectangle area between two rectangles
//  * A more forgiving implementation than dnd-kit's getIntersectionRatio
//  */
// function getIntersectionRatio(
//     entry: ClientRect, // container
//     target: ClientRect // active
// ): number {
//     const additionalTargetHeight = target.height / 2;
//     const additionalTargetWidth = target.width / 2;

//     const top = Math.max(target.top - additionalTargetHeight, entry.top); // smallest top
//     const left = Math.max(target.left - additionalTargetWidth, entry.left); // rightmost left
//     const right = Math.min(target.left + target.width + additionalTargetWidth, entry.left + entry.width);
//     const bottom = Math.min(target.top + target.height + additionalTargetHeight, entry.top + entry.height);
//     const width = right - left;
//     const height = bottom - top;

//     if (left < right && top < bottom) {
//         const targetArea = target.width * target.height;
//         const entryArea = entry.width * entry.height;
//         const intersectionArea = width * height;
//         const intersectionRatio =
//             intersectionArea / (targetArea + entryArea - intersectionArea);

//         return Number(intersectionRatio.toFixed(4));
//     }

//     // Rectangles do not overlap, or overlap has an area of zero (edge/corner overlap)
//     return 0;
// }

// /**
//  * Returns the closest rectangles from an array of rectangles to the center of a given
//  * rectangle.
//  */
// export const closestCenter: CollisionDetection = ({
//     collisionRect,
//     droppableRects,
//     droppableContainers,
// }) => {
//     const centerRect = centerOfRectangle(
//         collisionRect,
//         collisionRect.left,
//         collisionRect.top
//     );
//     const collisions: CollisionDescriptor[] = [];

//     for (const droppableContainer of droppableContainers) {
//         const { id } = droppableContainer;
//         const rect = droppableRects.get(id);

//         if (rect) {
//             const distBetween = distanceBetween(centerOfRectangle(rect), centerRect);

//             collisions.push({ id, data: { droppableContainer, value: distBetween } });
//         }
//     }

//     return collisions.sort(sortCollisionsAsc);
// };